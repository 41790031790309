<template>
  <div class="window" @click.self="$emit('end-editing')">
    <div class="signUp-container">
      <ul class="progression">
        <li><h3>Basic Information</h3></li>
        <li>
          <ion-icon name="arrow-forward-outline"></ion-icon>
        </li>
        <li><h3>About</h3></li>
        <li>
          <ion-icon name="arrow-forward-outline"></ion-icon>
        </li>
        <li><h3>Experience</h3></li>
      </ul>

      <form
          class="form"
          @submit.prevent="
          handleSubmit();
          if(checkEntry()){$emit('end-editing');}
        "
      >
        <div class="signUp-basic-info">
          <div class="basic-info-1st-line">
            <div class="first-item">
              <h4>First Name</h4>
              <input v-model="firstName" required type="text"/>
            </div>
            <div class="second-item">
              <h4>Last Name</h4>
              <input v-model="lastName" required type="text"/>
            </div>
          </div>
          <h4>Career Track</h4>
          <select id="careerTrack" v-model="careerTrack" class="selection-input" name="careerTrack"
                  required @change="updateCTcompanies()">
            <option value="default">Select your career track</option>
            <option value="Art & Design">Art & Design</option>
            <option value="Education & Training">Education & Training</option>
            <option value="Finance">Finance</option>
            <option value="Health & Pharmaceuticals">
              Health & Pharmaceuticals
            </option>
            <option value="Information Technology">Information Technology</option>
            <option value="Law & Government">Law & Government</option>
            <option value="Marketing">Marketing</option>
            <option value="Science & Engineering">Science & Engineering</option>
            <option value="Other">Other</option>
          </select>
          <h4>Company</h4>
          <select
              id="company"
              v-model="company"
              class="selection-input"
              name="company"
              required
          >
            <option value="default">Select your company</option>
            <option
                v-for="company in CTcompanies"
                :key="company.id"
                :value="company.id">
              {{ company.name }}
            </option>
            <option value="Other">Other</option>
          </select>
          <h4 v-if="company=='Other'">Company Name</h4>
          <input v-if="company=='Other'" v-model="otherCompany" required type="text"/>
          <h4>Major</h4>
          <input v-model="major" type="text"/>
          <h4>Cumulative GPA</h4>
          <input v-model="gpa" type="text"/>
          <h4>Graduation Year</h4>
          <input v-model="year" type="number"/>
        </div>

        <div>
          <h4>About</h4>
          <textarea v-model="about" required/>
        </div>

        <div class="signUp-experience">
          <div class="signUp-experiences">
            <div v-for="exp in exps" :key="exp.id" class="experience-template">
              <div class="job-header">
                <hr/>
                <h3>Job {{ exp.id }}</h3>
                <hr/>
              </div>
              <h4>Job Title</h4>
              <input v-model="exp.title" required type="text"/>
              <h4>Company</h4>
              <input v-model="exp.company" required type="text"/>
              <div class="checkbox">
                <input
                    id="current-in-role"
                    v-model="exp.currentInRole"
                    type="checkbox"
                />
                <h4>I am currently working on this role.</h4>
              </div>
              <div class="start-end">
                <div class="start-date date-template">
                  <h4>Start Date</h4>
                  <input v-model="exp.startDate" required type="date"/>
                </div>
                <div class="end-date date-template">
                  <h4>End Date</h4>
                  <input
                      v-if="!exp.currentInRole"
                      v-model="exp.endDate"
                      required
                      type="date"
                  />
                  <div v-else id="present-box">
                    <h3 id="in-roll-text">Present</h3>
                  </div>
                </div>
              </div>
              <h4>Description(Optional)</h4>
              <textarea v-model="exp.description"/>
            </div>
          </div>
          <div class="addAndDelete">
            <button
                v-if="exps.length < 2"
                class="add-another-job"
                @click.prevent="handleAddJob"
            >
              Add
              <p v-if="exps.length == 1">Another</p>
              <p v-if="exps.length == 0">a new</p>
              Job
            </button>
            <div class="empty-block">
              <p v-if="exps.length == 2">
                You can show at most two of your experiences.
              </p>
            </div>
            <button
                v-if="exps.length > 0"
                class="delete-job"
                @click.prevent="handleDeleteJob"
            >
              Delete Job
            </button>
          </div>
        </div>

        <button id="sign-up-btn" class="next-step" type="submit">Finish</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import getProfile from "@/composables/Profile/getProfile";
import getUser from "@/composables/getUser";
import editProfile from "@/composables/Profile/editProfile";
import getAllCompanies from "@/composables/WishList/getAllCompanies"
import createNewCompany from "@/composables/WishList/createNewCompany"
import {createToast} from "mosha-vue-toastify";
import 'mosha-vue-toastify/dist/style.css';

export default {
  emits: ["end-editing"],
  async setup() {
    const showBlock = ref(0);
    const {user} = getUser();
    const profile = ref(null);
    const firstName = ref(null);
    const lastName = ref(null);
    const careerTrack = ref("default");
    const company = ref(null);
    const major = ref(null);
    const gpa = ref(null);
    const year = ref(null);
    const about = ref(null);
    const exps = ref([]);
    const companies = ref([]);
    const CTcompanies = ref([]);
    const otherCompany = ref("");

    const updateCTcompanies = () => {
      let temp = []
      companies.value.forEach((company) => {
        if (company.careerTrack == careerTrack.value) {
          temp.push(company)
        }
      })
      CTcompanies.value = temp
    }
    
    watch(profile, async () => {
      firstName.value = profile.value.firstName;
      lastName.value = profile.value.lastName;
      careerTrack.value = profile.value.careerTrack;
      company.value = profile.value.company;
      major.value = profile.value.major;
      gpa.value = profile.value.GPA;
      year.value = profile.value.year;
      about.value = profile.value.about;
      exps.value = [];
      otherCompany.value = ""

      await getAllCompanies(companies)
      updateCTcompanies()

      if (profile.value.experience) {
        profile.value.experience.forEach((exp) => {
          let tempObj = {
            ...exp,
            startDate:
              exp.startDate.toDate().getFullYear() +
              "-" +
              ("0" + (exp.startDate.toDate().getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + exp.startDate.toDate().getDate()).slice(-2),
          };
          if (exp.endDate) {
            tempObj.endDate =
              exp.endDate.toDate().getFullYear() +
              "-" +
              ("0" + exp.endDate.toDate().getMonth()).slice(-2) +
              "-" +
              ("0" + exp.endDate.toDate().getDate()).slice(-2);
          }

          exps.value.push(tempObj);
        });
      }
    })
    await getProfile(profile, user.value.uid);

    const modifyExps = () => {
      const result = exps.value.map((exp) => {
        const startDateArr = exp.startDate.split("-");
        let startDateMod = new Date(
            startDateArr[0],
            startDateArr[1] - 1,
            startDateArr[2]
        );
        startDateMod = new firebase.firestore.Timestamp.fromDate(
            startDateMod
        ).toDate();
        let endDateMod;
        if (!exp.currentInRole) {
          const endDateArr = exp.endDate.split("-");
          endDateMod = new Date(
              endDateArr[0],
              endDateArr[1] - 1,
              endDateArr[2]
          );
          endDateMod = new firebase.firestore.Timestamp.fromDate(
              endDateMod
          ).toDate();
        } else {
          endDateMod = null;
        }
        return {
          ...exp,
          startDate: startDateMod,
          endDate: endDateMod,
        };
      });
      return result;
    };

    const checkEntry = () => {
      // console.log(parseFloat(gpa.value))
      if (
          firstName.value &&
          lastName.value &&
          about.value
      ) {
        if (careerTrack.value == "default") {
          return false;
        }
        if (company.value == "Other" && otherCompany.value == "") {
          return false;
        } else if (gpa.value && !parseFloat(gpa.value) || parseFloat(gpa.value) > 4 || parseFloat(gpa.value) < 0) {
          return false
        } else if (year.value && !Number.isInteger(parseFloat(year.value))) {
          return false
        } else {
          return true
        }
      }
      return false;
    };

    const notCorrectlyFilledAlert = () => {
      createToast({
        title: "Error on Inputs!",
        description: 'All fields must be correctly filled.'
      }, {
        type: "warning"
      })
    }

    const handleSubmit = async () => {
      if (checkEntry()) {
        if (company.value === "Other") {
          await createNewCompany(company, careerTrack, otherCompany)
        }
        await editProfile(
            {
              about: about.value,
              careerTrack: careerTrack.value,
              major: major.value,
              company: company.value,
              gpa: gpa.value,
              firstName: firstName.value,
              lastName: lastName.value,
              year: parseInt(year.value),
              experience: modifyExps(),
              currentCompany: profile.value.company
            },
            user.value.uid
        );
      } else {
        notCorrectlyFilledAlert()
      }
    };

    const handleAddJob = () => {
      exps.value.push({
        id: exps.value.length + 1,
        title: null,
        company: null,
        currentInRole: false,
        startDate: null,
        endDate: null,
        description: null,
      });
    };

    const handleDeleteJob = () => {
      if (exps.value.length > 0) {
        exps.value.pop();
      }
    };

    return {
      showBlock,
      firstName,
      lastName,
      careerTrack,
      company,
      otherCompany,
      major,
      gpa,
      year,
      about,
      exps,
      checkEntry,
      handleSubmit,
      handleAddJob,
      handleDeleteJob,
      CTcompanies,
      updateCTcompanies,
      notCorrectlyFilledAlert
    };
  },
};
</script>

<style scoped>
.window {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  height: 100vh;
  overflow-y: scroll;
  padding: 0 25vw;
  animation: fadein 0.5s;
}

@media screen and (max-width: 1000px) {
  .window {
    padding: 0 0 10vw 10vw;
  }
}

.signUp-container {
  padding-top: 7vh;
  padding-bottom: 1vh;
  background-color: white;
}

.signUp-small-container {
  opacity: 1;
  background-color: white;
  display: flex;
  margin: 0 15vw;
  align-items: center;
  flex-direction: column;
  padding: 3vh;
}

.form {
  width: 100%;
}

.progression {
  width: 100%;
  list-style: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 5vh;
  flex: 0 1 auto;
}

.progression li {
  margin-right: 2vw;
  align-items: center;
}

.basic-info {
  margin-top: 7vh;
}

.basic-info ul {
  list-style: none;
  align-items: center;
}

.basic-info-1st-line {
  margin: 0 auto;
  width: 50%;
  display: flex;
}

.selection-input {
  display: block;
  width: 50%;
  font-size: 120%;
  margin: 1vh auto;
  margin-bottom: 2vh;
  padding: 0 1vw;
  outline: none;
  border: 2px var(--purple-l) solid;
  border-radius: 20px;
  height: 35px;
}

.first-item {
  width: 14vw;
  flex: 1 0 45%;
  margin-right: 10%;
}

.second-item {
  width: 14vw;
  flex: 1 0 45%;
}

.first-item input,
.second-item input {
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 2px var(--purple-l) solid;
  border-radius: 20px;
  height: 35px;
  width: 100%;
}

.first-item h4,
.second-item h4 {
  width: 100%;
}

#sign-up-btn {
  display: block;
  margin: 4vh auto;
  font-size: 150%;
  width: 15vw;
  min-width: 150px;
  padding: 1vh 0;
}

.job-header {
  width: 50%;
  margin: 0 auto;
  height: 2vh;
  display: flex;
  margin-bottom: 1vh;
  margin-top: 5vh;
  align-items: center;
}

.job-header hr {
  display: table-cell;
  flex: 0 1 40%;
  height: 0px;
}

.job-header h3 {
  flex: 1 0 auto;
  text-align: center;
}

.checkbox {
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.checkbox input {
  flex: 0 1 10%;
  height: 25px;
  width: 25px;
  left: 495px;
  top: 421px;
  color: #cca2d8;
}

.checkbox input:checked {
  background-color: #cca2d8;
}

.checkbox h4 {
  flex: 1 0 auto;
}

.start-end {
  width: 50%;
  margin: 0 auto;
  display: flex;
}

.start-date {
  margin-right: 5%;
}

.date-template {
  flex: 1 0 45%;
}

.date-template h4 {
  width: 100%;
  margin-bottom: 1vh;
}

#present-box {
  height: 4vh;
}

#in-roll-text {
  height: 4vh;
  display: table-cell;
  vertical-align: middle;
}

.date-template input {
  width: 11vw;
}

.addAndDelete {
  display: flex;
  width: 50%;
  margin: 0 auto;
}

.add-another-job {
  flex: 0 1 auto;
  text-align: left;
  display: inline-block;
  padding: 0.5vh 1vw;
  border-radius: 1.5vh;
  border: 1.5px var(--purple-l) solid;
  background-color: transparent;
  color: var(--purple-l);
}

.add-another-job:hover {
  border-color: #c47cd8;
  color: #c47cd8;
  cursor: pointer;
}

.empty-block {
  flex: 1 0 auto;
}

.empty-block p {
  font-size: 80%;
}

.delete-job {
  flex: 0 1 auto;
  text-align: right;
  display: inline-block;
  border: none;
  background-color: transparent;
  color: var(--purple-l);
}

.delete-job:hover {
  color: #c47cd8;
  text-decoration: underline;
  cursor: pointer;
}

.add-another-job p {
  display: inline;
}

.next-step {
  margin-top: 2vh;
  border: none;
  background-color: var(--purple-l);
  color: white;
  font-weight: bold;
  font-size: 100%;
  padding: 1vh 0vw;
  width: 10vw;
  border-radius: 30px;
}

.next-step:hover {
  background-color: #c88fd8;
  padding: 1vh 0vw;
  cursor: pointer;
}

#next-step-btn {
  margin-left: 2vw;
}

#sign-up-btn {
  display: block;
  margin: 4vh auto;
  font-size: 150%;
  width: 15vw;
  padding: 1vh 0;
}
</style>
